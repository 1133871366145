.modal-dialog {

  .modal-content{
    background-color: $dark_blue;

    .modal-body {
      position:relative;
      padding: 60px 0;
      padding: 0px 0;
      background-color: rgba(000,000,000,.5);
      display: flex;
      justify-content: center;
      align-items: center;

      .container,
      .text_holder {
        position: relative;
        //background-color: white;
        width: 90%;
        height: auto;
        padding: 10% 5%;
        padding: 40px 20px;
        margin: auto;
        padding-top: 150px;
        //border: 1px solid red;

        .modal_text_button_close {
          z-index: 10;
          position: fixed;
          top: 30px;
          right: 100px;
          right: 20px;

          @include media-breakpoint-up(sm) {
            top: 50px;
          }
          @include media-breakpoint-up(md) {
            top: 50px;
            right: 50px;

            padding: 40px;
          }
          @include media-breakpoint-up(lg) {
            top: 75px;
            right: 75px;
          }
          @include media-breakpoint-up(xl) {
            top: 75px;
            right: 75px;
          }


          .burger_holder {
            position: relative;
            width: 30px;
            height: 24px;
            margin-left: 15px;
            margin-top: 18px;
            transition: .5s;

            span{
              background: white;
              border: none;
              height: 1px;
              width: 30px;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.35s ease;
              cursor: pointer;
              border-radius: 0px;

              &.top {
                transform: translateY(11px) translateX(0) rotate(45deg);
                background: white;
              }

              &.bottom {
                top: 22px;
                transform: translateY(-11px) translateX(0) rotate(-45deg);
                background: white;
              }
            }


            &:hover{
              transform: rotate(90deg);
            }
          }
        }

        h1 {
          color: white;
        }
        h2 {
          color: white;
          margin-left: 0px !important;
        }
        h3 {
          color: white;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        h4 {
          color: white;
          padding-top: 0px;
          font-weight: 700;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        p,a {
          color: white;
        }

        ul {

          li {
            color: white;
          }
        }

        .ro_ {

          .col {
            display: flex;
            justify-content: center;
            align-items: center;

            .modal_text {
              padding-left: 0px;

              .modal_headline_top,
              .modal_headline_bottom{
                display: flex;
                width: auto;

                h3 {
                  padding-bottom: 5px;
                }

                .leistungen_item_icon {
                  width: 30px;

                  .circle_blue_big {
                    transition: 0.5s;
                    left: -30px;
                  }
                  .circle_blue_small {
                    transition: 0.5s;
                    transform-origin: 0 0;
                  }

                  img, svg {
                    width: 30px;
                    margin: 0 auto;
                    margin-top: -30px;
                    margin-left: 5px;
                  }
                }
              }
              .modal_headline_bottom {
                padding-bottom: 20px;
              }

              @include media-breakpoint-up(lg) {
                padding-left: 50px;
              }
            }
          }
        }
      }
    }
  }
}

#datenschutzModal {

  &.show{
    opacity: 1 !important;
  }
}
