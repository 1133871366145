#footer {
  background: $dark_blue;

  .container-fluid {
    position: relative;
    padding-top: 20px;
    padding-bottom: 0px;

    .row {

      .col {

        .footerContent_left {
          text-align: left;
        }

        .footerContent_right {
          text-align: right;

          p, a {
            display: inline-block;
          }

          a {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

