.grid {
  z-index: 999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: none;

  .grid_item{
    position: absolute;
    top: 0px;
    left: 8.3333%;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.05);

    &.grid_item_01 {left: 8.3333%;}
    &.grid_item_02 {left: 16.6666%;}
    &.grid_item_03 {left: 25%;}
    &.grid_item_04 {left: 33.3333%;}
    &.grid_item_05 {left: 41.6666%;}
    &.grid_item_06 {left: 50%;}
    &.grid_item_07 {left: 58.33333%;}
    &.grid_item_08 {left: 66.6666%;}
    &.grid_item_09 {left: 75%;}
    &.grid_item_10 {left: 83.333333%;}
    &.grid_item_11 {left: 91.6666%;}
  }
}

.section {
  position: relative;
  background-color: #3498da;
  overflow-x: hidden;

  &.section_100 {
    height: 100%;
    min-height: 100%;
  }

  &.section_slider {

    .col {
      border: 1px solid white;
    }

  }

  .container,
  .container-fluid{
    padding-right: var(--bs-gutter-x, 0);
    padding-left: var(--bs-gutter-x, 0);
    padding-top: 10em;

    .row {
      margin-right: 0;
      margin-left: 0;

      .col {
        padding-right: calc(var(--bs-gutter-x) * 0);
        padding-left: calc(var(--bs-gutter-x) * 0);

        border: 1px solid red;
        border: 0px solid transparent !important;

        @include media-breakpoint-up(sm) {
          border: 1px solid yellow;
        }
        @include media-breakpoint-up(md) {
          border: 1px solid green;
        }
        @include media-breakpoint-up(lg) {
          border: 1px solid blue;
        }
        @include media-breakpoint-up(xl) {
          border: 1px solid red;
        }

        .text_line {
          width: 100%;
          height: 3px;
          background-color: $middle_blue;
          margin-bottom: 30px;
        }

        .image_text_teaser {
          width: 100%;

          img {
            padding-bottom: 20px;
            width: 100%;
          }
        }

        .image_teaser {
          width: 100%;

          img {
            padding-bottom: 20px;
            width: 100%;
          }

          .text_teaser{

          }
        }

        .social_media {
          border-radius: 50%;
          width: 36px;
          height: 36px;
          padding: 0px;
          background: #fff;
          border: 2px solid #666;
          color: #666;
          text-align: center;
          font-size: 22px;
          line-height: 36px;
        }

        .box_a {
          position: absolute;
          top: 75vh;
          left: 20%;
          background-color: black;
          width: 10vw;
          height: 10vw;
        }

        .box_green,
        .box_green2,
        .box_green3,
        .box_green4 {
          width: 100px;
          height: 100px;
          background-color: #28a92b;
          position: absolute;
          top: 500px;
          right: 100px;
          z-index: 100;
          line-height: 100px;
          font-size: 50px;
          text-align: center;
        }
        .box_green2 {
          top: 350px;
          right: 200px;
        }
        .box_green3 {
          top: 500px;
          left: 100px;
        }
        .box_green4 {
          top: 400px;
          left: 300px;
        }

        .line {
          width: 100%;
          max-width: 100%;
          height: 8px;
          margin: 0 0 10px 0;
          position: relative;
          display: inline-block;
          background-color: rgba(255,255,255,1);
        }

        .image_container {
          position: absolute;
          width: 300px;

          &.image_container_01 {

            top: 16%;
            left: 8%;
          }
          &.image_container_02 {
            top: 25%;
            left: 35%;
          }
          &.image_container_03 {
            top: 10%;
            left: 70%;
          }
          &.image_container_04 {
            top: 20%;
            left: 40%;
          }

          img {
            width: 100%;
          }
        }

        .text_container{
          position: absolute;
          width: 100%;

          h1 {
            text-align: center;
          }

          &.text_container_01 {
            top: 30%;
            left: 0px;
          }
          &.text_container_02 {
            top: 50%;
            right: 0px;


          }
        }

        .slick_caroussel_test {

          .slide{

            .label {
              font-size: 36px;
              line-height: 200px;
              background: #fff;
              color: #3498db;
              margin: 10px;
              padding: 2%;
              text-align: center;
            }
          }

          .slick-prev:before {
            content: "";
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgMjcgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5Hcm91cDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPgogICAgICAgIDxnIGlkPSJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMuNTAwMDAwLCAxNS41MDAwMDApIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMy41MDAwMDAsIC0xNS41MDAwMDApIHRyYW5zbGF0ZSgxLjAwMDAwMCwgMi4wMDAwMDApIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLDI2Ljk5ODMxMDggTDE0LjQ5ODMxMDgsMTMuNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTEsMC4wMDE2ODkxODkxOSBMMTQuNDk4MzEwOCwxMy41IiBpZD0iTGluZSI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMTEsMjYuOTk4MzEwOCBMMjQuNDk4MzEwOCwxMy41IiBpZD0iTGluZSI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMTEsMC4wMDE2ODkxODkxOSBMMjQuNDk4MzEwOCwxMy41IiBpZD0iTGluZSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
            background-size: cover;
            display: block;
          }

          .slick-next:before {
            content: "";
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgMjcgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5Hcm91cDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPgogICAgICAgIDxnIGlkPSJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wMDAwMDAsIDIuMDAwMDAwKSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNMSwyNi45OTgzMTA4IEwxNC40OTgzMTA4LDEzLjUiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLDAuMDAxNjg5MTg5MTkgTDE0LjQ5ODMxMDgsMTMuNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTExLDI2Ljk5ODMxMDggTDI0LjQ5ODMxMDgsMTMuNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTExLDAuMDAxNjg5MTg5MTkgTDI0LjQ5ODMxMDgsMTMuNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
            background-size: cover;
            display: block;
          }

          .slick-dots li button:before {
            color: white;
          }
        }
      }
    }
  }

  .container-fluid{
    padding-right: var(--bs-gutter-x, 0);
    padding-left: var(--bs-gutter-x, 0);
  }
}
