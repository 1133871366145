//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;


$dark_blue: #232937;
$middle_blue: #11679a;
$orange: #fb7402;
$green: #0aa1a6;

$color-primary: $dark_blue;
$color-secondary: $middle_blue;
$color-tertiary: $green;

.color_white {
  color: white;
}
.color_orange {
  color: $orange;
}
.color_green {
  color: $green;
}
.color_dark_blue{
  color: $dark_blue
}
.color_middle_blue{
  color: $middle_blue
}

$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: "~slick-carousel/slick/";
