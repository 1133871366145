#projects {
  background: white;
  overflow: hidden;

  .container {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .container-fluid{
    position: relative;

    .row {
      //height: 100vh;

      .col {

        .project_images {

          .project_image {
            cursor: pointer;
            display: inline-block;
            position: relative;
            width: 40%;
            max-width: 250px;
            max-width: 200px;
            height: auto;
            margin: 2%;

            @include media-breakpoint-up(sm) {
              width: 40%;
            }
            @include media-breakpoint-up(md) {
              width: 30%;
            }
            @include media-breakpoint-up(lg) {

            }
            @include media-breakpoint-up(xl) {
              //width: 250px;
            }

            img {
              transition: .5s;
              width: 100%;
              height: auto;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }

        .project_opfer_holder {
          z-index: 9;
          background-color: $middle_blue;
          height: auto;
          padding: 4.1666vw 25vw;
          padding: 4.1666vw 8.3333vw;
          padding: 8.3333vw 8.3333vw;

          .text_line {
            margin-left: -8.3333vw;
            margin-left: -8.3333vw;
            width: 16.6666vw;
            height: 3px;
            background-color: $dark_blue;
            margin-bottom: 30px;
          }

          .project_images {
            padding-top: 30px;
          }
        }

        .project_intro_content {
          background-image: url("./images/projekte/projekte_image_03.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          padding: 4.166% 0 0 0;
          padding: 16.166% 0 0 0;
          min-height: 100%;

          border: 1px solid transparent;

          .projects_intro_holder {
            z-index: 9;
            margin-left: 16.6666%;
            margin-top: 16.66%;
            margin-bottom: -4.166%;
            width: 75%;
            background-color: rgba(35,41,55,.9);
            height: auto;
            padding: 4.1666% 8.333%;
          }
        }
      }
    }
  }
}

