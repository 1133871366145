#header {
  //position: fixed;
  background: white;
  overflow: hidden;

  .header_text_holder {
    z-index: 9;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 8.333% 8.333%;
    background-color: $dark_blue;
    min-height: 100px;

    @include media-breakpoint-up(sm) {
      left: 8.333%;
      width: 66.666%;
    }
    @include media-breakpoint-up(md) {
      width: 58.333%;
    }
    @include media-breakpoint-up(lg) {


    }
    @include media-breakpoint-up(xl) {
      padding: 4.1666% 8.333%;
      width: 50%;
    }
  }

  .container-fluid{
    position: relative;
    padding-top: 0;
    min-height: 100%;

    .row {
      min-height: 100%;
      height: 100vh;

      .col {
        min-height: 80%;

        /*
        .header_logo {
          display: none;
          position: relative;
          top: 10%;
          max-width: 188px;
          width: 70%;
          margin: 0 auto;

          border: 1px solid red;

          @include media-breakpoint-up(sm) {
          }
          @include media-breakpoint-up(md) {

          }
          @include media-breakpoint-up(lg) {

          }
          @include media-breakpoint-up(xl) {

          }

          img {
            width: 100%;
          }
        }
        */

        .header_image_holder {
          position: relative;
          width: 100%;
          height: 90%;
          margin-top: 120px;
          overflow: hidden;

          @include media-breakpoint-up(sm) {
            margin-top: 150px;
          }
          @include media-breakpoint-up(md) {
            margin-top: 0px;
          }
          @include media-breakpoint-up(lg) {

          }
          @include media-breakpoint-up(xl) {

          }

          .header_image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("./images/header/header_image.jpg");
            background-size: cover;
            background-repeat: no-repeat;

            //border: 1px solid green;

          }
        }
      }
    }
  }
}

