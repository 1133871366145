#stiftung {
  background: white;
  overflow: hidden;


  .container{
    position: relative;

    .row {

      .col {


      }
    }
  }
}

