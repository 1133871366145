#contact_form {
  background: white;
  padding-bottom: 200px;

  .container-fluid{
    position: relative;

    .row {

      .col {

        #myForm,
        form{
          padding-top: 50px;

          // ERROR HIGHLIGHT
          .was-validated .form-control:invalid,
          .form-control.is-invalid,
          .was-validated .custom-select:invalid,
          .custom-select.is-invalid {
            border-color: #fb7402 !important;
          }

          .invalid-feedback,
          .error{
            color: #fb7402 !important;
            font-size: 12px;
          }

          .form-group {
            position: relative;
            padding-bottom: 50px;

            .form-control {

              &::placeholder {
                color: green;
                color: $dark_blue;
                font-size: 16px;
              }
            }

            select,
            input {
              margin-bottom: 5px;
              width: 100%;
              color: $dark_blue;
              font-size: 16px;
              outline: 0;
              border-width: 0 0 1px;
              border-color: green;
              border-color: $dark_blue;
              border-radius: 0;
              background-color: transparent;
            }

            textarea{
              outline: 0;
              height: 100px;
              color: $dark_blue;
              font-size: 16px;
              border-width: 0 0 1px;
              border-color: green;
              border-color: $dark_blue;
            }

            .custom-control {
              position: relative;
              max-width: 90%;
              margin-left: 5%;
              //border: 1px solid green;

              @include media-breakpoint-up(sm) {
                max-width: 90%;
                margin-left: 5%;
              }
              @include media-breakpoint-up(md) {
                max-width: 80%;
                margin-left: 10%;
              }
              @include media-breakpoint-up(lg) {
                max-width: 70%;
                margin-left: 15%;
              }
              @include media-breakpoint-up(xl) {
                max-width: 50%;
                margin-left: 25%;
              }
            }

            .custom-control-input {
              //position: absolute;
              display: inline-block;
              vertical-align:top;
              z-index: -1;
              width: auto;
              margin-left: -20px;
              //opacity: 0;
            }

            .custom-control-label {
              //position: absolute;
              display: inline-block;
              vertical-align:top;
              text-align: left;
              margin-top: 0px;
              margin-bottom: 3px;
              color: green;
              color: $dark_blue;
              font-size:12px;
              width: 100%;
              //border: 1px solid red;

              a {
                color: green;
                color: $dark_blue;
                text-decoration: underline;
                font-size:12px;
              }

              a:hover {
                color: blue !important;
                text-decoration: none !important;
              }
            }

            #policy-error {
              position: relative;
            }

            #form-messages{
              color: $middle_blue;
            }

            .button_gray {
              margin-top: 30px;
              cursor: pointer;
              display: inline-block;
              width: 160px;
              min-height: 36px;
              background-color:green;
              border: 1px solid green;

              -webkit-border-radius: 0px;
              -moz-border-radius: 0px;
              border-radius: 0px;
              color: white;

              a {
                font-size: 16px;
                line-height: 36px;
                color: white;
                text-align: center;
                text-decoration: none;
              }
            }
            .button_gray:hover {
              background-color: blue;
              border: 1px solid blue;
            }
          }
        }
      }
    }
  }
}

