#contact {
  background: $dark_blue;

  .container-fluid {
    position: relative;
    padding-top: 200px;
    padding-bottom: 200px;


    .row {

      .col {
        position: relative;
      }
    }
  }
}

