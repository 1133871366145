.navbar {
  position: absolute;
  left: 0;
  right: 0;
  top: -25px;
  top: 0px;
  z-index: 1000;
  width: 100%;
  width: 100%;
  padding: 0px;
  transition: 1000ms;

  .logo {
    z-index: 999;
    position: fixed;
    max-width: 150px;
    width: 70%;
    height: auto;
    margin: 0 auto;
    top: 20px;
    left: 20px;
    //transform: translateX(-50%);
    opacity: 1;
    transition: opacity 500ms;
    transform-origin: left top;

    .black {
      position: absolute;
      top: 0px;
      left: 0px;
      transition: 0.5s;
      opacity: 0;
      //border: 1px solid green;

      &.show {
        opacity: 1;
      }
    }

    .white {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      transition: 0.5s;

      &.show {
        opacity: 1;
      }
    }

    @include media-breakpoint-up(sm) {

    }
    @include media-breakpoint-up(md) {
      max-width: 150px;
      top:100px;
      left: 12.5%;
      transform: translateX(-50%);
    }
    @include media-breakpoint-up(lg) {
      max-width: 188px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 250px;
    }

    img, svg {
      width: 100%;
    }
  }

  #toggle {
    z-index: 10;
    position: fixed;
    top: 0px;
    right: 20px;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 0px;
    transition: 1000ms;
    background-color: $dark_blue;
    opacity: 0;

    @include media-breakpoint-up(sm) {
      top: 0px;
    }
    @include media-breakpoint-up(md) {
      top: 25px;
      right: 50px;
    }
    @include media-breakpoint-up(lg) {
      top: 25px;
      right: 75px;
    }
    @include media-breakpoint-up(xl) {
      top: 25px;
      right: 75px;
    }

    .burger_holder {
      position: relative;
      width: 30px;
      height: 24px;
      margin-left: 15px;
      margin-top: 18px;
      transition: .5s;

      span{
        background: white;
        border: none;
        height: 1px;
        width: 30px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.35s ease;
        cursor: pointer;
        border-radius: 0px;

        &.top {
        }
        &.middle {
          top: 11px;
        }
        &.bottom {
          top: 22px;
        }
      }

      &:hover{

        .top {
            width: 100%;
        }
        .middle {
            width: 80%;
        }
        .bottom {
            width: 60%;
        }
      }
    }

    &.active {

      .burger_holder {

        .top {
          transform: translateY(11px) translateX(0) rotate(45deg);
          background: white;
        }
        .middle {
          opacity: 0;
          background: white;
        }
        .bottom {
          transform: translateY(-11px) translateX(0) rotate(-45deg);
          background: white;
        }

        &:hover{
          transform: rotate(90deg);

          .top {
            width: 100%;
          }
          .middle {
            width: 100%;
          }
          .bottom {
            width: 100%;
          }
        }
      }
    }

    &.show {
      opacity: 1;
      top: 30px;

      @include media-breakpoint-up(sm) {
        top: 50px;
      }
      @include media-breakpoint-up(md) {
        top: 50px;
      }
      @include media-breakpoint-up(lg) {
        top: 75px;
      }
      @include media-breakpoint-up(xl) {
        top: 75px;
      }
    }
  }

  &.show {
    opacity: 1;
    top: 0px;
  }

  &.hide {

    .top-nav {

      &.left {
        top: -200px;
      }
      &.right {
        top: -200px;
      }
    }
  }
}

#mainNav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  //width: 20%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 1;
  visibility: hidden;

  -webkit-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

  nav {
    background: #2aa0e7;
    background: $dark_blue;
    position: absolute;
    right: -100%;
    top: 0px;
    transform: translateY(0);
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    align-items: center;

    -webkit-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -moz-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -o-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
    -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

    ul {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0 auto;
      position: relative;
      padding-left: 0px !important;

      li {
        display: block;
        line-height: 3rem;
        position: relative;
        opacity: 0;
        padding: 15px 0;
        text-align: center;

        .navi_spacer {
          margin: 0 auto;
          width: 100%;
          height: 50px;
          opacity: 0;
        }

        a, p {
          //font-family: "Gilroy-Regular", serif;
          font-size: 27px;
          line-height: 40px;
          display: block;
          position: relative;
          text-decoration: none;
          overflow: hidden;
          letter-spacing: 5px;
          transition: 300ms;
          padding-left: 5px;
          padding-bottom: 0px;

          &.medium {
            font-size: 20px;
            line-height: 20px;
          }
          &.small {
            font-size: 14px;
            line-height: 14px;
          }

          &:hover,
          &.active {
            color: $green !important;
            //text-decoration: underline;
          }
        }
      }
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    right: 0px;
    z-index: 3;

    nav {
      right: 0px;

      ul {

        .navi_spacer {
          animation: fadeInLeft 0.6s ease forwards;
          opacity: 0;
        }

        li {
          animation: fadeInLeft 0.6s ease forwards;
          opacity: 0;
          animation-delay: .2s;

          // referenzen
          &:nth-of-type(2) {
            animation-delay: .25s;
          }

          // ueber uns
          &:nth-of-type(3) {
            animation-delay: .3s;
          }

          // contact
          &:nth-of-type(4) {
            animation-delay: .35s;
          }

          // spacer
          &:nth-of-type(5) {
            animation-delay: .35s;
          }

          // blog
          &:nth-of-type(6) {
            animation-delay: .45s;
          }

          // faq
          &:nth-of-type(7) {
            animation-delay: .5s;
          }
          // language
          &:nth-of-type(8) {
            animation-delay: .55s;
          }
        }
      }
    }
  }
  &.closing {

    nav {

      ul {

        li {
          opacity: 1;
          animation: fadeOutLeft 0.4s ease forwards;

          // language
          &:nth-of-type(8) {
            animation-delay: 0s;
          }

          // faq
          &:nth-of-type(7) {
            animation-delay: 0.05s;
          }

          // blog
          &:nth-of-type(6) {
            animation-delay: 0.1s;
          }

          // line
          &:nth-of-type(5) {
            animation-delay: 0.15s;
          }

          // contact
          &:nth-of-type(4) {
            animation-delay:  0.2s;
          }

          // // ueber uns
          &:nth-of-type(3) {
            animation-delay: 0.25s;
          }

          // referenzen
          &:nth-of-type(2) {
            animation-delay: 0.3s;
          }

          // leistungen
          &:nth-of-type(1) {
            animation-delay: 0.35s;
          }
        }
      }
    }
  }
}


@keyframes fadeInLeft {
  0% {
    opacity: 0;
    left: 100px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 100px;
  }
}

@-webkit-keyframes animate_arrow_down {
  0% {
    -webkit-transform:  translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_down {
  0% {
    transform:  translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, 20px);
    opacity: 0;
  }
}

@-webkit-keyframes animate_arrow_up {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, -20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_up {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform:translate(0px, -20px);
    opacity: 0;
  }
}
