/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}


/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}


//! set font variables
$font-heading: 'Montserrat', sans-serif;
$font-body: 'Montserrat', sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 16px;
  color: $black;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }
}

h1 {
  font-family: $font-heading, serif;
  font-size: 60px;
  line-height: 80px;
  font-weight: 400;
  color: $dark_blue;
  margin: 0;
  padding-bottom: 30px;
  font-size: 3em;
  line-height: 1em;
}
h2 {
  font-family: $font-heading, serif;
  font-size: 40px;
  line-height: 60px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 30px;

  font-size: 2em;
  line-height: 1.5em;
}
h3 {
  font-family: $font-heading, serif;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
  color: $middle_blue;
  margin: 0;
  padding-bottom: 30px;

  font-size: 1.5em;
  line-height: 1.5em;

  font-size: 1.4em;
  line-height: 1.4em;
}
h4 {
  font-family: $font-heading, serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 20px;

  font-size: 1em;
  line-height: 1.5em;
}
p, a {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: $dark_blue;
  margin: 0;
  padding-bottom: 30px;

  font-size: 1em;
  line-height: 1.5em;

  @include media-breakpoint-up(sm) {
    font-size: .95em;
    line-height: 1.5em;
  }
  @include media-breakpoint-up(md) {
    font-size: 1em;
    line-height: 1.5em;
  }

  &.padding_right{
    padding-right: 30px;
  }

  &.footer,
  &.medium{
    font-size: 14px;
    line-height: 20px;
    color: white;

    font-size: .7em;
    line-height: 1.5em;
  }
}

.button_holder {
  padding-top: 10px;

  .button {
    cursor: pointer;
    color: white;
    font-size: 20px;
    font-size: 18px;
    line-height: 30px;

    padding: 10px 30px;
    border: 1px solid $green;
    background-color: $green;
    border-radius: 20px;
    transition: .5s;

    font-size: 1em;
    line-height: 1.5em;


    &:hover {
      background-color: transparent;
      color: $green;
    }
  }
}